// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/usePathName.ts"
);
import.meta.hot.lastModified = "1698840997309.7664";
}
// REMIX HMR END

import { useLocation, useNavigation } from "@remix-run/react";

export function usePathName(preemptive = true) {
  const navigation = useNavigation();
  const location = useLocation();

  if (!preemptive || navigation.state === "idle" || !navigation.location) {
    return location.pathname;
  }

  return navigation.location.pathname;
}
